<template>
  <div class="home">
    <header>
      <div class="header-content">
        <div class="container">
          <div class="row align-items-center pb-5 mb-5">
            <!-- animate__animated animate__fadeInLeft -->
            <div
              class="col-md-6 animate__animated animate__fadeInLeft"
              id="intro"
            >
              <h3>Skip the repair shop</h3>
              <p>OUR MECHANICS MAKE HOUSE CALLS</p>
              <h5>
                Service at your home or office · 7 days a week · Fair and
                transparent pricing
              </h5>
              <button type="button" class="btn btn-light btn-rounded mt-5">
                Get an instant
              </button>
              <!-- <a href="#">
                  <img src="./assets/images/appstore.png" class="w-25 img-responsive" alt="appstore link" />
                </a>
                <a href="#">
                  <img src="./assets/images/playstore.png" class="w-25 img-responsive" alt="appstore link" />
                </a> -->
            </div>
            <!-- animate__animated animate__fadeInRight -->
            <div
              class="col-md-6 animate__animated animate__fadeInRight d-none d-md-block text-center"
              id="img-pic"
            >
              <img
                src="./../assets/images/car-control.e81579a9.svg"
                alt="car control"
                class="img-responsive mt-5 mr-2 pr-2"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="1440"
        height="144"
        style="margin: -15px"
        viewBox="0 0 1440 144"
      >
        <defs>
          <filter
            id="Path_1"
            x="0"
            y="0"
            width="1440"
            height="144"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="6" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="4" result="blur" />
            <feFlood flood-opacity="0.231" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_1)">
          <path
            id="Path_1-2"
            data-name="Path 1"
            d="M0,0H1440V120L923.61,62.488,0,120Z"
            transform="translate(12 6)"
            fill="#000dff"
          />
        </g>
      </svg> -->

      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
        <path
          fill="#000DFF"
          fill-opacity="1"
          d="M0,96L720,32L1440,96L1440,0L720,0L0,0Z"
        ></path>
      </svg> -->
    </header>
    <main class="mt-4">
      <!-- services -->
      <section class="services mt-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="service p-4">
                <h4 class="text-primary font-weight-bold">WE MAKE IT EASY</h4>
                <p>
                  Get a quote and book a service online 24/7. Our mechanics will
                  come to your home or office, even on evenings and weekends.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="service border border-primary">
                <h4 class="text-primary font-weight-bold">
                  FAIR AND TRANSPARENT PRICING
                </h4>
                <p>
                  We offer fair and transparent pricing and provide estimates
                  upfront for hundreds of services on thousands of cars. Book
                  with confidence.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="service">
                <h4 class="text-primary font-weight-bold">
                  HAPPINESS GUARANTEED
                </h4>
                <p>
                  We only work with highly rated mechanics. All services are
                  backed by our 12-month / 12,000-mile warranty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- quotes -->
      <section class="quote">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
          <path
            fill="#FFFFFF"
            fill-opacity="1"
            d="M0,32L480,96L960,128L1440,288L1440,0L960,0L480,0L0,0Z"
          ></path>
        </svg>

        <div class="container">
          <h2 class="pb-3 pt-2 border-bottom my-5">
            Life’s too short to spend it at the repair shop
          </h2>
          <!--first section-->
          <div class="row align-items-center how-it-works d-flex">
            <div
              class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center get_quote"
            >
              <div class="circle font-weight-bold">1</div>
            </div>
            <div class="col-6 get_quote">
              <h5>GET A QUOTE</h5>
              <p>
                Tell us what your car needs or ask for a diagnostic. Receive a
                free, fast, fair & transparent price quote
              </p>
            </div>
          </div>
          <!--path between 1-2-->
          <div class="row timeline">
            <div class="col-2">
              <div class="corner top-right"></div>
            </div>
            <div class="col-8">
              <hr />
            </div>
            <div class="col-2">
              <div class="corner left-bottom"></div>
            </div>
          </div>
          <!--second section-->
          <div
            class="row align-items-center justify-content-end how-it-works d-flex book_app"
          >
            <div class="col-6 text-right">
              <h5>BOOK APPOINTMENT</h5>
              <p>
                Provide your home or office location. Tell us when to meet you
                there.
              </p>
            </div>
            <div
              class="col-2 text-center full d-inline-flex justify-content-center align-items-center book_app animate__animated animate__fadeInRight"
            >
              <div class="circle font-weight-bold">2</div>
            </div>
          </div>
          <!--path between 2-3-->
          <div class="row timeline">
            <div class="col-2">
              <div class="corner right-bottom"></div>
            </div>
            <div class="col-8">
              <hr />
            </div>
            <div class="col-2">
              <div class="corner top-left"></div>
            </div>
          </div>
          <!--third section-->
          <div class="row align-items-center how-it-works d-flex">
            <div
              class="col-2 text-center top d-inline-flex justify-content-center align-items-center get_quote"
            >
              <div class="circle font-weight-bold">3</div>
            </div>
            <div class="col-6 get_quote">
              <h5>GET YOUR CAR FIXED</h5>
              <p>
                That’s it. No more waiting in repair shops - our mechanics come
                to you..
              </p>
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
          <path
            fill="#FFFFFF"
            fill-opacity="1"
            d="M0,32L480,96L960,128L1440,288L1440,320L960,320L480,320L0,320Z"
          ></path>
        </svg>
      </section>
      <!-- makes -->
      <section class="makes mt-4">
        <div class="container">
          <h2 class="pb-3 pt-2 border-bottom mb-5">
            We service most makes and models
          </h2>
          <carousel
            :nav="false"
            :autoplay="true"
            :margin="10"
            :items="6"
            :loop="true"
            :center="true"
            :dots="false"
            :autoWidth="true"
            :autoplayTimeout="3000"
          >
            <div class="brand-wrapper" v-for="brand in brands" :key="brand">
              <img :src="brand" alt="car title" class="img-fuid" />
            </div>
          </carousel>
        </div>
      </section>

      <section class="parallax contaier-overlay mt-5" id="parralax">
        <div class="scroll-overlay"></div>
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-md-6 wow fadeInLeft"
              style="z-index: 17; color: #fff; margin-top: 100px"
            >
              <h3 class="text-center para-head">Skip the repair shop</h3>
              <p class="lead text-center">
                OUR MECHANICS MAKE HOUSE CALLS Service at your home or office·
              </p>
              <p class="lead text-center">
                7 days a week · Fair and transparent pricing
              </p>
            </div>
            <div class="col-md-6 wow fadeInRight" style="z-index: 17">
              <img
                src="../assets/images/header screenshot.png"
                class="img-fluid mt-4 d-none d-md-block"
                alt="phones"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="faq mt-4">
        <div class="container">
          <h2 class="pb-3 pt-2 border-bottom mb-2">Faq</h2>
          <p class="my-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
            veniam fuga tempore reiciendis nemo qui ea quasi cupiditate
            consectetur eum consequuntur aperiam asperiores excepturi amet
            repellendus .
          </p>
          <!-- start accordian -->
          <div class="accordion mb-4" id="accordionExample">
            <div
              class="card border border-primary border-bottom-0"
              v-for="item in faqs"
              :key="item.id"
            >
              <div class="card-header bg-dark text-center">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link text-white font-weight-bold"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#cord' + item.id"
                    aria-expanded="true"
                    aria-controls="cord0"
                  >
                    {{ item.title }}
                  </button>
                </h2>
              </div>
              <div
                :id="'cord' + item.id"
                :class="{ collapse: true, show: item.id == 0 }"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <p class="p-4">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
          <!-- end accordian -->
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import Footer from "../components/partiels/Footer.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import "animate.css";

export default {
  name: "Home",
  components: {
    carousel,
    Footer,
  },
  data() {
    return {
      brands: [
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FBMW.png?alt=media&token=09fd2eb8-4969-462a-ad56-8842348f7f48",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FAlfa%20Romeo.png?alt=media&token=aee2fe83-dd8a-4773-aba5-96dfdf798bdb",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FAudi.png?alt=media&token=44515d4a-615a-433d-ad33-810dbf324e5c",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FChevrolet.png?alt=media&token=7a6cc1c7-f358-4aff-8979-bcd0d9baa06c",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FCitroen.png?alt=media&token=654b9241-3656-4480-8144-d108083b8987",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FFerrari.png?alt=media&token=359e2f3c-e12a-4dda-b49a-47a2148f9ae1",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FFiat.png?alt=media&token=ab691f02-6c32-4f05-a66f-18b67174d5e4",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FHonda.png?alt=media&token=9e68cb23-4bd7-45c9-b791-c87579899235",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FHyundai.png?alt=media&token=f5734e02-21d8-4fd1-9c11-37061e851a2d",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FJaguar.png?alt=media&token=7f082ecb-c252-4aed-a062-53a5f8c4c97b",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FLancia.png?alt=media&token=2bc9f42e-d8c5-45d2-bfe6-14418cb28465",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FMercedes.png?alt=media&token=3cb3f74d-8067-414d-9260-2894b579f425",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2FMitsubishi.png?alt=media&token=c04cec90-4a71-470b-8640-86827d4aefa6",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2Fporsche.png?alt=media&token=1fcfec08-57f9-4380-b86a-4720b5d33457",
        "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/car%20makes%2Ftoyata.png?alt=media&token=a8807cb9-336f-48db-ac8c-892570b58411",
      ],
      faqs: [
        {
          id: 0,
          title: "Tell us about your car ?",
          description:
            "Just enter your location and vehicle registration on the homepage so we know which car we're fixing. Then you'll need to select the work that needs doing. If you're not sure what's wrong with your car, don't worry! You can either use our diagnostic tool, or book a diagnostic inspection and the mechanic will let you know.",
          isSelected: false,
        },
        {
          id: 1,
          title: "You get an instant quote?",
          description:
            "Then we'll put this information into our quote engine and you'll get an industry standard approved quote within seconds.",
          isSelected: false,
        },
        {
          id: 2,
          title: "Confirm your booking?",
          description:
            "Let us know your preferred date, time, and location for the work to be done. To finalise your booking, you'll need to enter your bank card details into our 256-bit SSL encrypted website to be used by our secure payments provider Stripe. We won't take a penny from you until the mechanic has completed any work. It's just like a hotel reservation - we will reserve the booking amount on your card 24 hours before the booking so we can ensure the mechanic gets paid.",
          isSelected: false,
        },
        {
          id: 3,
          title: "On the day?",
          description:
            "Your mechanic will collect any parts they need and arrive at your chosen location within your requested arrival window. They'll introduce themselves and either take your car to the garage or get on with the work that you have selected. Once all work has been agreed and finished (and your car returned if it was taken to a garage), your mechanic will explain the work they carried out. Once you're happy, your securely stored bankcard will be charged through the Carixin app that all of the mechanics carry on their smart phone. The invoice from your mechanic will be sent directly to your email. After the visit, you'll receive an email asking you to rate your mechanic. This helps us ensure our standards remain high and improve the service for the next time you use us.",
          isSelected: false,
        },
      ],
    };
  },
  mounted() {
    console.log("mounted");
    // gsap.from("#intro", { x: -200, duration: 1 });
    // gsap.from("#img-pic", { x: 200, duration: 1 });
    // gsap.from(".get_quote", {
    //   scrollTrigger: ".get_quote",
    //   x: -300,
    //   opacity: 0,
    //   duration: 1,
    // });
    // gsap.from(".book_app", {
    //   scrollTrigger: ".get_quote",
    //   x: 300,
    //   opacity: 0,
    //   duration: 1,
    // });
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 2500px;
}
header {
  color: white;
  .header-content {
    display: flex;
    align-items: center;
    height: 100vh;
    // background-color: #000dff !important;
    background: url("../assets/images/header.svg") repeat-x;
    background-position: bottom;
    background-size: auto;
  }
  #intro button {
    font-weight: bold;
    color: #000dff;
    background: white !important;
  }
}
main {
  .service {
    height: 300px;
    width: 300px;
    margin: 1rem auto;
    padding: 1.5rem;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.15),
      -5px -5px 20px 3px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.4s;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &:hover {
      transform: scale(0.9, 0.9);
      border-radius: 30px;
      background-color: #000dff;
      color: #fff;
      font-weight: 400;
      font-size: 1.2rem;
      box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.15),
        -5px -5px 30px 15px rgba(0, 0, 0, 0.12);
    }
  }
  .quote {
    background: #000dff;
    color: #ffffff;

    .circle {
      padding: 13px 20px;
      border-radius: 50%;
      background-color: #ffffff;
      color: #000dff;
      max-height: 50px;
      z-index: 2;
    }

    .how-it-works.row .col-2 {
      align-self: stretch;

      &::after {
        content: "";
        position: absolute;
        border-left: 3px solid #ffffff;
        z-index: 1;
      }
      &.bottom::after {
        height: 50%;
        left: 50%;
        top: 50%;
      }
      &.full::after {
        height: 100%;
        left: calc(50% - 3px);
      }
      &.top::after {
        height: 50%;
        left: 50%;
        top: 0;
      }
    }

    .timeline {
      div {
        padding: 0;
        height: 40px;
      }
      hr {
        border-top: 3px solid #ffffff;
        margin: 0;
        top: 17px;
        position: relative;
      }
      .col-2 {
        display: flex;
        overflow: hidden;
      }
      .corner {
        border: 3px solid #ffffff;
        width: 100%;
        position: relative;
        border-radius: 15px;
      }
      .top-right {
        left: 50%;
        top: -50%;
      }
      .left-bottom {
        left: -50%;
        top: calc(50% - 3px);
      }
      .top-left {
        left: -50%;
        top: -50%;
      }
      .right-bottom {
        left: 50%;
        top: calc(50% - 3px);
      }
    }
  }
  .makes {
    .brand-wrapper {
      width: 100%;
      height: 175px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 125px;
      }
    }
  }
}
.parallax {
  height: 510px;
  width: 100vw;
  /* padding-top: 20vh; */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
#parralax {
  background-image: url("../assets/images/mm.jpg");
}
.contaier-overlay {
  position: relative;
}
.contaier-overlay .scroll-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6;
  bottom: 0;
  right: 0;
}
.para-head {
  padding: 30px;
  font-size: 44px;
  margin: 0 0 30px;
  font-weight: 550;
}
</style>
