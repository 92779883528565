import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// fontawesome
import "@/assets/css/all.min.css";
import "@/assets/webfonts/fa-brands-400.ttf";

import "@/assets/css/style.css";
import store from "./store";
import { auth } from "./firebase";
import "animate.css";
import VueSocketIO from "vue-socket.io";
import Axios from "axios";

Vue.config.productionTip = false;

global.jq = require("jquery");
var $ = global.jq;
window.$ = $;
window.jQuery = $;
window.dt = require("datatables.net-bs4");

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: "https://carixin.com/",
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

let app;
auth.onAuthStateChanged(() => {
  if(auth.currentUser != null){
    auth.currentUser.getIdToken().then((token) => {
      Axios.defaults.headers.common["Authorization"] = "Bearer "+token;
    });
  }

  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
