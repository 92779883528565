<template>
  <div class="">
    <div class="users">
      <form method="POST" autocomplete="on">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="email"
            v-model.trim="email"
            autocomplete="email"
            required
          />
        </div>
        <div class="form-group  position-relative">
          <label for="password">password</label>
          <input
            :type="[!eye ? 'password' : 'text']"
            id="password"
            class="form-control"
            placeholder="password"
            v-model.trim="password"
            required
          />
          <span class="eye" @click="eyeToggle"
            ><i
              :class="['fa', !eye ? 'fa-eye' : 'fa-eye-slash']"
              aria-hidden="true"
            ></i
          ></span>
        </div>

        <button
          type="submit"
          id="save"
          class="btn btn-primary btn-lg btn-block btn-rounded mt-4"
          @click.prevent="save"
        >
          <div class="spinner-border text-white" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else>
            Login
          </div>
        </button>
        <div class="text-center">
          <a
            class="btn btn-link"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#reset_password"
            >Forgot password ?</a
          >
        </div>
        <div class="my-2">
          <div class="alert alert-danger" role="alert" v-if="this.error">
            <strong>Error : </strong> {{ this.error }}
          </div>
        </div>
      </form>
    </div>
    <!-- Reset Password Modal -->
    <div
      class="modal fade"
      id="reset_password"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reset_password_title"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reset password</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Email reset -->
            <div class="md-form mt-3">
              <input
                type="text"
                id="email_reset"
                class="form-control"
                placeholder="email@email.com"
                v-model.trim="email_reset"
              />
              <label class="active" for="email_reset">Email</label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="resetPassword()"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scope>
span.eye {
  position: absolute;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
}
</style>

<script>
import axios from "axios";
import { auth } from "@/firebase";
import swal from "sweetalert";

export default {
  data() {
    return {
      email: null,
      password: null,
      error: null,
      token: null,
      loading: false,
      eye: false,
      email_reset: null,
    };
  },
  methods: {
    save() {
      if (this.email && this.password) {
        this.setLoading(true);
        axios
          .post("/api/users/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            console.log(response);
            auth
              .signInWithEmailAndPassword(this.email, this.password)
              .then(async (userCredential) => {
                // var user = userCredential.user;
                // const token = await auth.currentUser.getIdToken();
                // context.commit("setToken", token);
                this.$store.dispatch("fetchUser", userCredential);
                this.setLoading(false);
                this.$router.push("/");
              })
              .catch((error) => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                this.error = error.message;
                this.setLoading(false);
              });
          })
          .catch((error) => {
            this.error = error;
            this.setLoading(false);
          });
      } else {
        this.error = "Email and password are required";
      }
    },
    setLoading(status) {
      this.loading = status;
    },
    eyeToggle() {
      this.eye = !this.eye;
    },
    resetPassword() {
      if (this.email_reset)
        auth
          .sendPasswordResetEmail(this.email_reset)
          .then(() => {
            swal({
              title: "Success",
              text: "A password reset email sent successfully ",
              icon: "success",
            });
          })
          .catch((error) => {
            var errorMessage = error.message;
            swal("Oops", errorMessage.toString(), "error");
          });
    },
  },
};
</script>
